<script lang="ts" setup>

  // Props
  const props = defineProps({
    titulo: String,
    subtitulo: String
  })

  // Scroll Store
  const scroll = useScrollStore()
  const { isScrolledInput } = storeToRefs(scroll)

  const parallaxText = ref<HTMLElement | null>(null)

  const handleScroll = () => {
    const scrollPosition = window.scrollY
    if (parallaxText.value && window.innerWidth >= 768) {
      parallaxText.value.style.transform = `translateY(${scrollPosition * 0.3}px)`
    }
  }

  onMounted(() => {
    window.addEventListener('scroll', handleScroll)
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll)
  })

  const [tituloPart1, tituloPart2] = props?.titulo?.split(' ') ?? []

</script>


<template>
  <div class="flex flex-col w-full justify-center max-w-screen-lg mx-auto gap-4 z-10">

    <div :class="`bg-[url('https://images.byhours.com/img-web/home/background.webp')] md:bg-none bg-cover bg-center bg-no-repeat`">

      <div class="flex flex-col relative m-5 shadow sm:shadow-none border border-gray-100/30 pt-2 rounded-3xl backdrop-blur-sm sm:backdrop-blur-none">

        <!-- Overlay con degradado -->
        <div class="md:hidden absolute inset-0 bg-gradient-to-b from-black/[.15] to-white/25 rounded-3xl"></div>

        <div ref="parallaxText" class="text-center p-4 md:pt-4 md:mb-4">
          <h1 class="text-7xl text-white md:text-black drop-shadow md:drop-shadow-none sm:text-9xl lg:text-[10rem] pb-2 text-wrap">
            <span class="block md:inline md:mr-8">{{ tituloPart1 }}</span>
            <span class="block md:inline">{{ tituloPart2 }}</span>
          </h1>
          <p class="text-md md:text-xl md:pb-1 text-white drop-shadow md:drop-shadow-none md:text-gray-400">{{ subtitulo }}</p>
        </div>
        <div :class="`absolute top-20 left-0 p-4 h-[30rem] sm:h-[65vh] md:h-[35vh] rounded-3xl md:container mx-auto md:relative hidden md:flex flex-col justify-center gap-5 md:top-0`">
          <div class="md:absolute top-0 rounded-3xl h-full inset-0 backdrop-blur md:backdrop-blur bg-[#605c54] md:bg-[#605c54]/10">
            <NuxtImg
              provider="s3Provider"
              preload
              format="webp"
              src="/home/background.webp"
              alt="Fondo cama hotel"
              class="brightness-75 md:brightness-100 rounded-3xl object-cover w-full h-full opacity-100 md:opacity-75"
            />
          </div>  
        </div>

        <div v-if="!isScrolledInput" class="md:mx-auto p-3 md:-mt-10">
          <SearchInput/>
        </div>
        <div v-else class="h-40 md:h-6">
        </div>

      </div>

    </div>

  </div>
</template>